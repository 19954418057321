<template>
  <div id="holll">
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="holidayPopup"
      :header="`공휴일 설정`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="500"
      height="800"
      :isModal="true"
      :close="onHolidayPopupClosed"
    >
      <div class="window holidaySetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field year">
                    <div class="title">년도</div>
                    <ul class="content">
                      <li class="item">
                        <input-number
                          v-model="searchBsnYear"
                          :allowDot="false"
                          :allowMinus="false"
                          :displayComma="false"
                          :propMaxLength="4"
                          @change="yearChanged"
                        ></input-number>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">공휴일 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              buttonDiv="SAVE"
                              :is-custom-shortcut-button="true"
                              shortcut-key="holidayPopup.shortcuts.add"
                              :shortcut="{key: 'F3'}"
                              :isIconCustom="true"
                              @click.native="addHoliday"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              buttonDiv="DELETE"
                              :is-shortcut-button="true"
                              :isIconCustom="true"
                              @click.native="deleteHoliday"
                          >
                            삭제
                          </erp-button>
                        </li>
                        <li class="reset">
                          <erp-button
                              buttonDiv="GET"
                              @click.native="cancelHoliday"
                              :isIconCustom="true"
                          >
                            초기화
                          </erp-button>
                        </li>
                        <li class="copy">
                          <erp-button
                              buttonDiv="SAVE"
                              @click.native="copyHolidayLayerOpen"
                              :isIconCustom="true"
                          >
                            복사
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="holidayGrid"
                      :provides="holidayGridProvides"
                      :dataSource="holidayList"
                      :columns="holidayGridColumns"
                      :isNOColumnDisplay="false"
                      :rowHeight="24"
                      :validationRules="holidayGridValidationRules"
                      @cellEdit="gridCellEdit"
                      @cellSave="gridCellSave"
                      @queryCellInfo="holidayGridCustomiseCell"
                      @headerCellInfo="holidayGridHeaderCellInfo"
                    >
                    </ejs-grid-wrapper>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="saveHolidayPopup"
              >
                저장
              </erp-button>
            </li>

            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeHolidayPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
        <div class="windowModal" v-if="isHolidayCopyOpen">
          <!-- 복사 레이어. ejs-dialog 내의 layer(show/hide 처리가 필요한)는 ejs-dialog 안의 최상단 div로 사용하면 안 됨 -->
          <div class="modal-outer">
            <div class="modal-inner">
              <div class="modal-content">
                <div class="content-wrapper">
                  <div class="content-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-01">
                        <div class="section-body">
                          <div class="body-box">
                            <div class="item">
                              <div class="form">
                                <input-number
                                  v-model="holidayCopy.srcYear"
                                  :allowDot="false"
                                  :allowMinus="false"
                                  :displayComma="false"
                                  :propMaxLength="4"
                                />
                              </div>
                              <div class="text">년도 자료를</div>
                            </div>
                            <div class="item">
                              <div class="form">
                                <input-number
                                  v-model="holidayCopy.destYear"
                                  :allowDot="false"
                                  :allowMinus="false"
                                  :displayComma="false"
                                  :propMaxLength="4"
                                />
                              </div>
                              <div class="text">년도에 복사합니다.</div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <ul class="button">
                  <li class="copy keyColor">
                    <erp-button
                        buttonDiv="SAVE"
                        :isIconCustom="true"

                        button-div="GET"
                        :is-icon-custom="true"

                        @click.native="copyHoliday"
                    >
                      복사
                    </erp-button>
                  </li>
                  <li class="close">
                    <erp-button
                        button-div="CLOSE"
                        @click.native="closeCopyLayout">
                      닫기
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, Resize } from "@syncfusion/ej2-vue-grids";
import ErpButton from "@/components/button/ErpButton.vue";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { getTodayNavigationDateOfYear } from "@/utils/date";
import {
  getHolidayList,
  saveHolidayList,
  copyHolidayList,
} from "@/api/calendar";
import InputNumber from "@/components/common/InputNumber";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "HolidayPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    InputNumber,
    ErpButton

  },
  watch: {
    searchBsnYear() {
      this.holidayCopy.srcYear = this.searchBsnYear;
      this.holidayCopy.destYear = this.searchBsnYear + 1;
    },
  },
  data() {
    return {
      holidayGridProvides: [Edit, Resize],
      holidayList: [],
      searchBsnYear: null,
      holidayGridValidationRules: {
        bsnDate: {
          required: true,
          duplicateCheck: true,
        },
        hldyName: {
          required: true,
          maxLength: 50,
        },
      },
      holidayCopy: {
        srcYear: null,
        destYear: null,
      },
      bsnDateValue: null, // 적용일자 timezone 제거를 위한 변수
      isHolidayCopyOpen: false,
    };
  },
  computed: {
    holidayGridColumns() {
      return [
        {
          field: "bsnYear",
          visible: false,
          type: "string",
        },
        {
          field: "bsnDate",
          isPrimaryKey: true,
          headerText: "공휴일",
          minWidth: 16,
          width: 120,
          isDateType: true,
          dateProperty: { format: "YYYY-MM-DD" },
        },
        {
          field: "hldyName",
          type: "string",
          headerText: "공휴일명",
          minWidth: 16,
          width: 180,
        },
      ];
    },
  },
  created: function () {},
  methods: {
    showHolidayPopup: function (bsnYear) {
      this.searchBsnYear = bsnYear;
      this.viewHoliday();
      this.$refs.holidayPopup.show();
    },
    saveHolidayPopup: function () {
      if (this.$refs.holidayGrid.validate()) {
        const changedObj = this.$refs.holidayGrid.getBatchChanges();
        saveHolidayList(changedObj)
          .then(() => {
            this.$refs.holidayGrid.batchSave();
            this.$emit("popupClosed", { isReload: true });
            this.$refs.holidayPopup.hide();
            this.infoToast(this.$t("main.popupMessage.saved"));
          })
          .catch((error) => {
            console.log("saveHolidayList.err.===>", error);
          });
      }
    },
    closeHolidayPopup: function () {
      this.$refs.holidayPopup.hide();
    },
    onHolidayPopupClosed: function () {
      this.$emit("popupClosed");
    },
    gridCellEdit: function (args) {
      if (args.columnObject.type === "date" && args.columnName === "bsnDate") {
        this.bsnDateValue = args.value;
      }
    },
    gridCellSave: function (args) {
      if (args.columnObject.type === "date" && args.columnName === "bsnDate") {
        args.value = this.bsnDateValue;
      }
    },
    addHoliday: function () {
      this.$refs.holidayGrid.addRecord({
        bsnYear: this.getGridBsnYearDefaultValue(),
        bsnDate: this.getGridBsnDateDefaultValue(),
      });
    },
    deleteHoliday: function () {
      this.$refs.holidayGrid.deleteRecord();
    },
    cancelHoliday: function () {
      this.$refs.holidayGrid.batchCancel();
    },
    copyHolidayLayerOpen: function () {
      this.isHolidayCopyOpen = true;
    },
    closeCopyLayout: function () {
      this.isHolidayCopyOpen = false;
    },
    viewHoliday: function () {
      getHolidayList(this.searchBsnYear)
        .then((response) => {
          this.holidayList = response.value.holidayList;
        })
        .catch((error) => {
          console.log("getHolidayList.err.===>", error);
        });
    },
    copyHoliday: function () {
      if (!this.holidayCopy.srcYear || !this.holidayCopy.destYear) {
        this.errorToast("복사할 년도를 입력해 주십시오");
      } else {
        // 복사 대상(destYear) 년도에 자료가 있는지 여부 확인
        getHolidayList(this.holidayCopy.destYear)
          .then((response) => {
            if (
              response.value.holidayList &&
              Array.isArray(response.value.holidayList) &&
              response.value.holidayList.length > 0
            ) {
              if (
                confirm(
                  this.holidayCopy.destYear +
                    "년도에 공휴일 데이터가 존재합니다. 복사하시겠습니까?"
                )
              ) {
                // TODO : 공휴일 복사 API 호출
                this.copyHolidayListAPI();
              }
            } else {
              this.copyHolidayListAPI();
            }
          })
          .catch((error) => {
            console.log("getHolidayList.err.===>", error);
          });
      }
    },
    copyHolidayListAPI: function () {
      copyHolidayList(this.holidayCopy.srcYear, this.holidayCopy.destYear)
        .then(() => {
          this.isHolidayCopyOpen = false;
        })
        .catch((error) => {
          console.log("copyHolidayList.err.===>", error);
        });
    },
    holidayGridCustomiseCell: function (args) {
      // 수정영역 셀 처리
      if (args.column.field === "hldyName" || args.column.field === "check") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    holidayGridHeaderCellInfo: function (args) {
      if (
        args.cell.column.field === "bsnDate" ||
        args.cell.column.field === "hldyName"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    yearChanged() {
      this.viewHoliday();
    },
    getGridBsnYearDefaultValue() {
      return this.searchBsnYear;
    },
    getGridBsnDateDefaultValue() {
      return getTodayNavigationDateOfYear(this.searchBsnYear); // this.holidayOption.bsnYear 를 그대로 쓰면, 년도가 바뀔 때마다 grid가 갱신된다
    },
  },
};
</script>
