<!--TODO:api (저장,삭제,조회) 공통코드공휴일도  갈아껴야됨 / 팝업 api 도 전부 수정 추후 처리예정   (GH)-->
<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field year">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item">
                <input-number
                    v-model="searchConditions.bsnYear"
                    :allowDot="false"
                    :allowMinus="false"
                    :displayComma="false"
                    :propMaxLength="4"
                ></input-number>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="getCalendarListAPI"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캘린더 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      v-on:click.native="saveCalendar"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="roomCalendarRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="addRoomCalendarPopup"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="deleteRoomCalendar"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="cancelRoomCalendarGrid">
                    초기화
                  </erp-button>
                </li>
                <li class="settings keyColor">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="openHolidayPopup"
                  >
                    공휴일 설정
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="roomRealtimeWaitPopup()"
                  >
                    실시간예약 설정
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                      v-model="isModifyMode"
                      :checked="isModifyMode"
                      @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                  :showCloseButton="false"
                  heightAdjustMode="Auto"
                  :selected="onCalendarMonthTabSelected"
                  overflowMode="Popup"
                  :selectedItem="calendarTabIndex"
              >
                <e-tabitems>
                  <e-tabitem
                      v-for="n in 12" :key="n"
                      :header="{ text: `${n}월` }"
                  />
                </e-tabitems>
              </ejs-tab>
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-0101">
                <div class="section-body">
                  <ejs-grid-wrapper
                      ref="roomCalendarGrid"
                      :provides="gridProvides"
                      id="roomCalendarGrid"
                      :dataSource="roomCalendarList"
                      :columns="calendarColumns"
                      :allowExcelExport="true"
                      :allowFiltering="!isModifyMode"
                      :allowSorting="!isModifyMode"
                      :isAutoSelectCell="!isModifyMode"
                      :enableVirtualization="false"
                      :validationRules="calendarGridValidationRules"
                      :selectionSettings="calendarGridSelectionSettings"
                      :editSettings="calendarGridEditSettings"
                      @queryCellInfo="roomCalendarGridCustomiseCell"
                      @cellSelected="roomCalendarGridCellSelected"
                      @gridCheckboxChanged="roomCalendarGridCheckboxChanged"
                      @headerCellInfo="roomCalendarGridHeaderCellInfo"
                      @actionComplete="roomCalendarGridActionComplete"

                  />
<!--                      @cellEdit="calendarGridCellEdit"-->
                </div>
              </section>
            </article>
          </div>
        </section>
      </article>
    </div>
    <add-room-calendar-popup
        v-if="isAddRoomCalendarPopupOpen"
        ref="addRoomCalendarPopup"
        @popupClosed="onAddRoomCalendarPopupClosed"
    />
`    <holiday-popup
        v-if="isHolidayPopupOpen"
        ref="holidayPopup"
        @popupClosed="onHolidayPopupClosed"
    />
    <room-realtime-wait-reservation-popup
        v-if="isRoomRealtimeWaitPopupOpen"
        ref="roomRealtimeWaitPopup"
        @popupClosed="onRoomRealtimeWaitPopupClosed"
    />
    <edit-multiple-columns-popup
        v-if="isEditMultipleColumnsPopupOpen"
        ref="editMultipleColumnsPopup"
        @popupConfirm="onEditMultipleColumnsPopupConfirm"
        @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.year .content .item {width: 90px;}
body .appContent .body-article .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import Vue from 'vue';
import moment from 'moment';
import {
  Edit,
  Resize,
  ForeignKey,
  TimePickerEditCell,
  ExcelExport,
} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import { numberWithCommas } from '@/utils/number';
import { formNotPassedRequiredCheck } from '@/utils/formUtil';
import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaption, getFormattedDate,
  getTodayNavigationDate,
} from '@/utils/date';

import GolfErpAPI from "@/api/v2/GolfErpAPI";

//수정
// import {
//   getCalendarList,
//   saveCalendarList,
//   deleteCalendar,
// } from '@/api/calendar';
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
} from '@/utils/commonCodes';
import InputNumber from '@/components/common/InputNumber';
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import AddRoomCalendarPopup from '../popup/AddRoomCalendarPopup';
import HolidayPopup from '@/views/golf-reservation/popup/HolidayPopup';
import RoomRealtimeWaitReservationPopup from '../popup/RoomRealtimeWaitReservationPopup';
import GridDateTimeEdit from '@/views/layout/components/js/GridDateTimeEdit';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import ErpButton from "@/components/button/ErpButton";

Edit.AddEditors({ timepicker: TimePickerEditCell });

export default {
  name: 'RoomCalendarRegistration',
  components: {
    ejsGridWrapper,
    InputNumber,
    editMultipleColumnsPopup,
    AddRoomCalendarPopup,
    HolidayPopup,
    RoomRealtimeWaitReservationPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    isPopupOpened() {
      return (
          this.isAddRoomCalendarPopupOpen ||
          this.isHolidayPopupOpen ||
          this.isRoomRealtimeWaitPopupOpen ||
          this.isCalendarWeatherPopupOpen ||
          this.isEditMultipleColumnsPopupOpen
      );
    },
    calendarTabIndex() {
      return this.searchConditions.bsnMt - 1;
    },
  },
  data() {
    return {
      count: 0,
      gridProvides: [Edit, Resize, ForeignKey, ExcelExport],
      calendarColumns: [
        {
          field: 'bsnDate',
          headerText: '영업일자',
          isPrimaryKey: true,
          allowEditing: false,
          type: 'string',
          textAlign: 'center',
          minWidth: 16,
          width: 100,
        },
        {
          field: 'dwName',
          headerText: '요일',
          allowEditing: false,
          type: 'string',
          textAlign: 'center',
          minWidth: 16,
          width: 70,
        },
        {
          field: 'roomBsnCode',
          headerText: '영업구분',
          minWidth: 16,
          width: 90,
          editType: 'dropdownedit',
          isCommonCodeField: true,
          textAlign: 'center',
          groupCode: 'ROOM_BSN_CODE',
          edit: { params: { sortOrder: 'none' } },
        },
        {
          field: 'hldyFlag',
          headerText: '공휴일',
          minWidth: 16,
          width: 80,
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'Center',
        },
        {
          field: 'hldyName',
          headerText: '공휴일명',
          type: 'string',
          minWidth: 16,
          width: 120,
        },
        {
          field: 'yyIw',
          headerText: '년주차',
          minWidth: 16,
          width: 80,
          type: 'string',
          isNumericType: true,
          textAlign: 'center',
        },
        {
          field: 'mtIw',
          headerText: '월주차',
          minWidth: 16,
          width: 80,
          type: 'string',
          isNumericType: true,
          textAlign: 'center',
        },
        {
          headerText: '실시간 오픈일정',
          columns: [
            {
              field: 'webOpenDt',
              headerText: '오픈일시',
              type: 'string',
              edit: new GridDateTimeEdit('webOpenDt', 'yyyy-MM-dd HH:mm'),
              minWidth: 16,
              width: 210,
              textAlign: 'Left',
              isDatetimeType: true,
              valueAccessor: (field, data) => {
                // Open 일시에 텍스트 추가
                const webOpenDt = moment(data.webOpenDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
                const bsnDate = data['bsnDate'];
                if (webOpenDt) {
                  const bsnDateMoment = moment(
                      `${bsnDate}`,
                      DATE_FORMAT_YYYY_MM_DD
                  );
                  const webOpenDtMoment = moment(
                      webOpenDt.split(' ')[0],
                      `${DATE_FORMAT_YYYY_MM_DD}`
                  );

                  if (webOpenDtMoment.isValid()) {
                    const diff = bsnDateMoment.diff(webOpenDtMoment, 'days');

                    if (diff !== 0) {
                      return `${webOpenDt} ${diff} 일전 ${getDayOfWeekCaption(
                          webOpenDtMoment.toDate()
                      )}요일`;
                    } else {
                      return webOpenDt;
                    }
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              },
            },
            {
              field: 'webEndDt',
              headerText: '종료일시',
              type: 'string',
              edit: new GridDateTimeEdit('webEndDt', 'yyyy-MM-dd HH:mm'),
              minWidth: 16,
              width: 180,
              textAlign: 'Left',
              isDatetimeType: true,
              valueAccessor: (field, data) => {
                if(moment(data.webEndDt).isValid()) {
                  return data.webEndDt ? moment(data.webEndDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`) : null;
                }else {
                  return null;
                }
              },
            },
          ],
        },
        {
          field: 'remarks',
          headerText: '비고',
          type: 'string',
          minWidth: 16,
          width: 240,
          maxLength: 400,
          isRemarks: true,
        },
        {
          field: 'insertUserName',
          headerText: '등록자',
          allowEditing: false,
          type: 'string',
          width: 110,
          textAlign: 'left',
        },
        {
          field: 'insertDt',
          headerText: '등록일시',
          allowEditing: false,
          type: 'string',
          width: 130,
          textAlign: 'center',
        },
        {
          field: 'updateUserName',
          headerText: '수정자',
          allowEditing: false,
          type: 'string',
          width: 110,
          textAlign: 'left',
        },
        {
          field: 'updateDt',
          headerText: '수정일시',
          allowEditing: false,
          type: 'string',
          width: 130,
          textAlign: 'center',
        },
      ],
      calendarGridValidationRules: {
        hldyName: {
          maxLength: 50,
          custom: {
            method: (args) => {
              if (args.hldyFlag) {
                return !formNotPassedRequiredCheck(args.hldyName);
              }
              return true;
            },
            message: 'main.validationMessage.requiredMessage',
          },
        },
        remarks: {
          maxLength: 400,
        },
      },
      roomCalendarList: [],
      calendarListOptions: {
        bsnCodeOptions: [],
      },
      searchConditions: {
        bsnYear: null,
        bsnMt: null,
      },
      numberTemplate() {
        // TODO : 공통 모듈로??
        return {
          template: Vue.component('editOption', {
            template: '<span>{{parseInt(data.index) + 1}}</span>',
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      calendarGridSelectionSettings: { mode: 'Both' },
      calendarGridEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: 'Batch',
        showConfirmDialog: false,
      },
      calendarItemMaxLengthRules: {
        maxLength: [50, '최대 50자까지 입력하실 수 있습니다'], // TODO : 추후 정리 및 공통모듈화 필요
      },
      calendarRemarksMaxLengthRules: {
        maxLength: [400, '최대 400자까지 입력하실 수 있습니다'], // TODO : 추후 정리 및 공통모듈화 필요
      },
      numericParams: {
        params: {
          // 편집 시 소숫점 이하 자릿수 제한을 위함. TODO : 공통모듈화
          validateDecimalOnType: true,
          decimals: 0,
          format: 'N',
        },
      },
      webOpenDtValue: null, // 오픈일시 timezone 제거를 위함
      webOpenDtEdit: {
        params: {
          change: (e) => {
            console.log(e.element.value);
            this.webOpenDtValue = e.element.value;
          },
        },
      },
      isAddRoomCalendarPopupOpen: false,
      isHolidayPopupOpen: false,
      isWeekendReservationPopupOpen: false,
      isRoomRealtimeWaitPopupOpen: false,
      isCalendarWeatherPopupOpen: false,
      isEditMultipleColumnsPopupOpen: false,
      isModifyMode: false,
    };
  },
  async created() {
    const now = await getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD);

    const nowYear = new Date(now).getFullYear();
    const nowMonth = new Date(now).getMonth() + 1;

    const queryBsnYear = this.$route.query.bsnYear;
    const queryBsnMt = this.$route.query.bsnMt;

    // 올해, 현재 달 설정 - query 값이 있으면 그 값으로 설정
    if (
        queryBsnYear &&
        !isNaN(queryBsnYear) &&
        queryBsnMt &&
        !isNaN(queryBsnMt)
    ) {
      this.searchConditions.bsnYear = parseInt(queryBsnYear);
      this.searchConditions.bsnMt = parseInt(queryBsnMt);
    } else {
      this.searchConditions.bsnYear = nowYear;
      this.searchConditions.bsnMt = nowMonth;
    }

    this.calendarListOptions.bsnCodeOptions = commonCodesGetCommonCode(
        'BSN_CODE'
    );

    this.getCalendarListAPI();
  },
  methods: {
    numberWithCommas,
    onCalendarMonthTabSelected(args) {
      this.searchConditions.bsnMt = args.selectedIndex + 1;
      this.getCalendarListAPI();
    },
    async saveCalendar() {
      if(!this.$refs.roomCalendarGrid.validate()) {
        return;
      }
      let saveData = this.$refs.roomCalendarGrid.patchBatchChanges();

      if(saveData.changedRecords && saveData.changedRecords.length > 0) {
        saveData.changedRecords.forEach(element=>{
          for(let data in element){
            if(element[data] === null) {
              element[data] = "";
            }
            if(data === "webOpenDt" || data === "webEndDt") {
              element[data] = this.patchConvertedCalendar(element, 'YYYY-MM-DD HH:mm:ss' ,data);
            }
          }
        });
        await GolfErpAPI.patchRoomCalendarList(saveData.changedRecords);
        await this.infoToast("수정되었습니다");
        await this.getCalendarListAPI();
      } else {
        this.errorToast("수정된 데이터가 없습니다");
      }
    },
    getCalendarListAPI() {
      //api 바뀌어야됨
      // 칼렌다 조회 API 호출

      GolfErpAPI.getRoomCalendarList(this.searchConditions)
          .then(response => {
            this.roomCalendarList = response.map(item =>
               this.getConvertedCalendar(item, 'YYYY-MM-DD HH:mm')
            );
          })
          .catch((error) => {
            console.log('getCalendarList.err.===>', error);
          });
    },
    roomCalendarGridCustomiseCell(args) {
      const {
        column: {
          field,
          allowEditing
        },
        cell,
        data,
      } = args;
      if (allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if(field === 'dwName'){
        cell.style.color = commonCodesGetColorValue("DW_CODE", data.dwCode);
      } else if(field === 'roomBsnCode'){
        cell.style.color = commonCodesGetColorValue("ROOM_BSN_CODE", data.roomBsnCode);
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.calendarColumns
            ?.filter(item => item.allowEditing)
            ?.map(item => item.field)
            ?.concat(['webOpenDt','webEndDt']);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }

    },
    roomCalendarGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;
      if (this.isModifyMode) {
        const allowedEditColumns = this.calendarColumns
            ?.filter(item => item.allowEditing)
            ?.map(item => item.field)
            ?.concat(['webOpenDt','webEndDt']);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    patchConvertedCalendar(calendar, format ,field) {
      const webDtMoment = new moment(calendar[field]);
      return webDtMoment.isValid() && calendar[field]
          ? webDtMoment.format(format)
          : "";
    },
    getConvertedCalendar(calendar, format) {
      const webOpenDtMoment = new moment(calendar.webOpenDt);
      const webEndDtMoment = new moment(calendar.webEndDt);
      return {
        ...calendar,
        webOpenDt: webOpenDtMoment.isValid() && calendar.webOpenDt
            ? webOpenDtMoment.format(format)
            : null,
        webEndDt: webEndDtMoment.isValid() && calendar.webEndDt
            ? webEndDtMoment.format(format)
            : null,
      };
    },
    roomCalendarGridActionComplete() {
      this.count = this.$refs.roomCalendarGrid?.getGridBatchCount() || 0  ;
    },
    addRoomCalendarPopup() {
      this.isAddRoomCalendarPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.addRoomCalendarPopup.showCalendarPopup(
            this.searchConditions.bsnYear
        );
      });
    },
    async deleteRoomCalendar() {
      if (
          await this.confirm(
              this.searchConditions.bsnMt + '월 캘린더 자료를 삭제하시겠습니까?'
          )
      ) {
       await GolfErpAPI.deleteRoomCalendar(this.searchConditions);
       await this.infoToast("삭제 되었습니다");
       await this.getCalendarListAPI();
      }
    },
    onAddRoomCalendarPopupClosed(event) {
      this.isAddRoomCalendarPopupOpen = false;
      if (event && event.isReload === true) {
        this.getCalendarListAPI();
      }
    },
    cancelRoomCalendarGrid() {
      this.$refs.roomCalendarGrid.batchCancel();
    },
    onClickExcel() {
      this.$refs.roomCalendarGrid.excelExport();
    },
    openHolidayPopup() {
      this.isHolidayPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.holidayPopup.showHolidayPopup(this.searchConditions.bsnYear);
      });
    },
    onHolidayPopupClosed(event) {
      this.isHolidayPopupOpen = false;
      if (event && event.isReload === true) {
        this.getCalendarListAPI();
      }
    },
    async roomRealtimeWaitPopup(dateFrom = null, dateTo = null) {
      this.isRoomRealtimeWaitPopupOpen = true;
      await this.$nextTick();
      this.$refs.roomRealtimeWaitPopup.showRealtimeWaitReservationPopup(
          getFormattedDate(
              dateFrom ||
              new Date().setFullYear(
                  this.searchConditions.bsnYear,
                  this.searchConditions.bsnMt - 1,
                  1
              )
          ),
          dateTo && getFormattedDate(dateTo)
      );
    },
    onRoomRealtimeWaitPopupClosed(event) {
      this.isRoomRealtimeWaitPopupOpen = false;
      if (event && event.isReload === true) {
        this.getCalendarListAPI();
      }
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.calendarGridSelectionSettings = isChecked
          ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
          : {mode: 'Both'};
      this.$refs.roomCalendarGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async roomCalendarGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.calendarColumns
          ?.forEach(column => {
            if (column?.columns) {
              column.columns
                  ?.forEach(c => columns.push(c));
            } else {
              columns.push(column);
            }
          });
      const allowedEditColumns = columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
      );
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.roomCalendarGrid.updateCell(
            index,
            field,
            value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    roomCalendarGridCheckboxChanged(args) {
      if (
          args.columnName === 'hldyFlag' &&
          args.value === true &&
          (args.rowData.roomBsnCode === 'WEEKDAY' || args.rowData.roomBsnCode === 'CLOSE' )
      ) {
        // 공휴일 체크 시, 영업구분이 주중(WEEKEDAY) 또는 '휴장(CLOSE) 이면 '주말'(WEEKEND)로 자동 변경
        this.$refs.roomCalendarGrid.updateCell(
            this.$refs.roomCalendarGrid.getRowIndexByPrimaryKey(args.rowData.bsnDate),
            'roomBsnCode',
            'WEEKEND'
        );
      }else if(
          args.columnName === 'hldyFlag' &&
          args.value === false &&
          args.rowData.roomBsnCode === 'WEEKEND'
      ) {
        this.$refs.roomCalendarGrid.updateCell(
            this.$refs.roomCalendarGrid.getRowIndexByPrimaryKey(args.rowData.bsnDate),
            'roomBsnCode',
            'WEEKDAY'
        );
      }
    }
  },
};
</script>
